<template>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center mt-4">
      <div class="col-lg-5">
        <div class="text-center mb-4">
          <h4 class="fw-semibold fs-22">{{ $t('tariff') }} & {{ $t('Prices') }}</h4>
          <button v-if="this.perms['900'] == true" class="btn btn-success btn-label" @click="modalcalculator = true" >
            <i class="ri-calculator-line label-icon align-middle fs-16 me-2"></i>{{ $t('Calculator') }}
          </button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-xl-9">
        <div class="row">
          <div class="col-xxl-4">
            <div class="card pricing-box">
              <div class="card-body p-4 m-2">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <h5 class="mb-1 fw-semibold">Businessman</h5>
                    <p class="text-muted mb-0">{{ $t('Cafe_title') }}, {{ $t('coffee_shop_title') }}, {{ $t('food_truck_title') }}</p>
                  </div>
                  <div class="avatar-sm">
                    <div
                      class="avatar-title bg-light rounded-circle text-primary"
                    >
                      <i class="bx bx-coffee-togo fs-20"></i>
                    </div>
                  </div>
                </div>
                <div class="pt-4">
                  <h2>
                    <sup><small>₴</small></sup
                    >360 <span class="fs-13 text-muted">/{{ $t('month') }}</span>
                  </h2>
                </div>
                <hr class="my-4 text-muted" />
                <div>
                  <ul class="list-unstyled text-muted vstack gap-3">
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Outlets') }}:</div>
                        <div class="flex-grow-1 text-right"><b>1</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_users') }}:</div>
                        <div class="flex-grow-1 text-right"><b>∞</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_transactions') }}:</div>
                        <div class="flex-grow-1 text-right"><b>∞</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_goods') }}:</div>
                        <div class="flex-grow-1 text-right"><b>600</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_technical_cards') }}:</div>
                        <div class="flex-grow-1 text-right"><b>60</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('statistics') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Inventory_control') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Checkout_and_user_shifts') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Discounts_and_promotions') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Screen_for_the_kitchen') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Receipt_printing') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('fiscal_registrar') }} | <b>UK, RU, KZ</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Customization_possible') }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-xxl-4">
            <div class="card pricing-box ribbon-box right">
              <div class="card-body p-4 m-2">
                <div class="ribbon-two ribbon-two-danger">
                  <span>Popular</span>
                </div>
                <div>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <h5 class="mb-1 fw-semibold">Corporation</h5>
                      <p class="text-muted mb-0">{{ $t('bar_title') }}, {{ $t('shop_title') }}, {{ $t('bakery_title') }}</p>
                    </div>
                    <div class="avatar-sm">
                      <div
                        class="
                          avatar-title
                          bg-light
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="ri-shopping-basket-2-line fs-20"></i>
                      </div>
                    </div>
                  </div>

                  <div class="pt-4">
                    <h2>
                      <sup><small>₴</small></sup
                      >630 <span class="fs-13 text-muted">/{{ $t('month') }}</span>
                    </h2>
                </div>
                </div>
                <hr class="my-4 text-muted" />
                <div>
                 <ul class="list-unstyled text-muted vstack gap-3">
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Outlets') }}:</div>
                        <div class="flex-grow-1 text-right"><b>1 +</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_users') }}:</div>
                        <div class="flex-grow-1 text-right"><b>∞</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_transactions') }}:</div>
                        <div class="flex-grow-1 text-right"><b>∞</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_goods') }}:</div>
                        <div class="flex-grow-1 text-right"><b>1000</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_technical_cards') }}:</div>
                        <div class="flex-grow-1 text-right"><b>120</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('statistics') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Inventory_control') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Checkout_and_user_shifts') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Discounts_and_promotions') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Screen_for_the_kitchen') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Receipt_printing') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('fiscal_registrar') }} | <b>UK, RU, KZ</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Customization_possible') }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-xxl-4">
            <div class="card pricing-box">
              <div class="card-body p-4 m-2">
                <div>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <h5 class="mb-1 fw-semibold">BigBoss</h5>
                      <p class="text-muted mb-0">{{ $t('restaurant_title') }}, {{ $t('Confectionery_title') }}, {{ $t('market_title') }}</p>
                    </div>
                    <div class="avatar-sm">
                      <div
                        class="
                          avatar-title
                          bg-light
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="bx bx-restaurant fs-20"></i>
                      </div>
                    </div>
                  </div>
                <div class="pt-4">
                    <h2>
                      <sup><small>₴</small></sup
                      >900 <span class="fs-13 text-muted">/{{ $t('month') }}</span>
                    </h2>
                </div>
                </div>
                <hr class="my-4 text-muted" />
                <div>
                 <ul class="list-unstyled text-muted vstack gap-3">
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Outlets') }}:</div>
                        <div class="flex-grow-1 text-right"><b>1+</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_users') }}:</div>
                        <div class="flex-grow-1 text-right"><b>∞</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_transactions') }}:</div>
                        <div class="flex-grow-1 text-right"><b>∞</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_goods') }}:</div>
                        <div class="flex-grow-1 text-right"><b>3500</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0">{{ $t('Number_of_technical_cards') }}:</div>
                        <div class="flex-grow-1 text-right"><b>300</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('statistics') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Inventory_control') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Checkout_and_user_shifts') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Discounts_and_promotions') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Screen_for_the_kitchen') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Receipt_printing') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('fiscal_registrar') }} | <b>UK, RU, KZ</b></div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                            class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">{{ $t('Customization_possible') }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <calculator v-if="modalcalculator" @close="modalcalculator = false"></calculator>
</template>

<script>
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import calculator from "@/components/calc_tariff.vue"
import { storeS } from '@/store';

export default {
  page: {
    title: "Pricing",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Pricing",
      items: [
        {
          text: "Pages",
          href: "/",
        },
        {
          text: "Pricing",
          active: true,
        },
      ],
      modalcalculator: false,
      data: {}
    };
  },
  methods: {
    
  },
  computed: {
    perms(){
      return storeS.perms
    }
    
  },
  mounted() {
    
  },
  components: {
    PageHeader,
    calculator
  },
};
</script>

<style scoped>
.text-right {
    text-align: right;
}
:root {
  --veryDarkCyan: #374158;
  --darkGrayishCyan: hsl(186, 14%, 43%);
  --lightGrayishCyan: hsl(162deg 39% 42%);
  --white: hsl(0, 0%, 100%);
  --strongCyan: hsl(162deg 40% 41%);
}
.calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  /* margin: 50px auto; */
}

.block-calc {
  background-color: #374158;
  padding: 50px;
  border-radius: 41px;
  font-family: 'Proxima Nova Bold';
}

.calculator .bill {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-size: 17px;
}

.calculator .bill input {
  width: 100%;
  max-width: 400px;
  padding: 8px;
  text-align: right;
  color: #374158;
  font-weight: 700;
  font-size: 24px;
  border-radius: 4px;
  border: none;
  margin-top: 8px;
  /* background-image: url("./images/icon-dollar.svg"); */
  background-repeat: no-repeat;
  background-position-x: 8px;
  background-position-y: center;
  background-size: 16px;
}

.calculator .tip {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  margin-top: 24px;
  font-size: 17px;
  /* margin-left: 67px; */
}

.calculator div {
  width: 100%;
  /* width: 500px; */
  max-width: 400px;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 12px;
  justify-content: center;
}

.discont_desc{
  color: #5d777c;
  font-size: 14px;
}
.calculator div button {
  padding: 12px;    
  background-color: #374158;
  color: #41957c;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  /* width: 200px; */
}

.calculator div input {
  font-size: 24px;
  font-weight: 700;
  color: #374158;
  text-align: center;
  border-radius: 4px;
  border: none;
}
.calculator .bill input:focus {
  background: white;
  color: #374158;
}
.calculator .people {
  width: 100%;
  max-width: 400px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  color: var(--darkGrayishCyan);
}

/*  */

.result {
  /* background-color: #374158; */
  padding: 3%;
  width: 100%;
  max-width: 500px;
  margin-left: 12px;
  border-radius: 16px;
  min-height: 60vh;
  position: relative;
  /* box-shadow: 7px 7px 33px rgb(0, 0, 0, 0.3); */
}

.result div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result div div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.result div:nth-child(2) {
  margin-top: 24px;
}

.result div div p {
  color: #41957c;
  font-weight: 700;
  font-size: 20px;
}

.result div div span {
  color: var(--darkGrayishCyan);
}

.result .value {
  font-size: 48px;
  color: #3f9279;
  font-weight: 700;
}

.result .reset-btn {
  background-color: #3f9279;
  color: #374158;
  border: none;
  width: 88%;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  bottom: 8%;
  left: 6%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
}
.reset-btn a {
  color: #374158;
  text-decoration: none;
}
#getpred {
  bottom: 18%;
  display:none;
}

@media (max-width: 840px) {
  /* .container {
    flex-direction: column;
  } */
  .result {
    margin-top: 24px;
    margin-left: 0;
  }
}

@media (max-width: 560px) {
  /* .container {
    padding: 24px;
    margin-top: 24px;
  } */
  .calculator div {
    grid-template-columns: 48% 48%;
  }
  .result {
    min-height: 45vh;
    padding: 8%;
  }
  .result .value {
    font-size: 32px;
  }
}
.ityped {
  text-transform: capitalize;
}
.quantity_inner * {
    box-sizing: border-box;
}
.quantity_inner {
  display: flex!important;
  justify-content: center;
  grid-template-columns: 100%!important;
}
.quantity_inner .bt_minus,
.quantity_inner .bt_plus,
.quantity_inner .quantity {
  color: #41937b;
  height: 47px;
  width: 60px;
  padding: 0;
  margin: 7px 0px;
  border-radius: 10px;
  border: 4px solid #41937b;
  font-size: 26px;
}
.quantity_inner .bt_minus {
  color: white;
  border: 4px solid #e82b2b;
  background-color: #e82b2b;
}
.quantity_inner .bt_plus {
  background: #41937b;
  color: white;
}
</style>